import React, { useRef, useState, AnimationEvent } from 'react'
import { useVirtual } from 'react-virtual'
import { numberOfSnacks, snackForIndex, snacks } from '../model/Snacks'
import useWindowHeight from '../hooks/useWindowSize'
import imageLogo from '../images/other/logo_lelijk.png';
import './app.css'

const numberOfItems = 1000000
const itemHeight = 200
const scrollDistanceMinimumNumberOfItems = numberOfSnacks()
const scrollDurationMillis = 4000

function outQuadInterpolation(t: number) {
  return t * (2 - t)
}

enum ScreenState {
  INTRO,
  SCROLLING,
  SELECTED
}

export default function App() {
  const parentRef = useRef<HTMLDivElement>(null)
  const [screenState, setScreenState] = useState<ScreenState>(ScreenState.INTRO)
  const [targetIndex, setTargetIndex] = useState(3) // Start index is around 3 on an average screen
  const [selectedImageClassName, setSelectedImageClassName] = useState("layoutSelectedImageScaleUp")
  const [backgroundSelectedClassName, setBackgroundSelectedClassName] = useState("backgroundSelectedFadeIn")

  console.log("a");

  const windowHeight = useWindowHeight()

  console.log("b");

  const scrollToFn = React.useCallback((offset, defaultScrollTo) => {
    const scrollingUp = (parentRef?.current?.scrollTop ?? 0) >= offset
    const startPosition = parentRef?.current?.scrollTop ?? 0
    const startTime = Date.now()
    const extraOffsetToCenterItemAfterScroll = (windowHeight - itemHeight) / 2
    offset = offset + extraOffsetToCenterItemAfterScroll * (scrollingUp ? -1 : 1)

    const run = () => {
      const now = Date.now()
      const elapsed = now - startTime
      const progress = outQuadInterpolation(Math.min(elapsed / scrollDurationMillis, 1))
      const interpolated = startPosition + (offset - startPosition) * progress

      if (elapsed < scrollDurationMillis) {
        defaultScrollTo(interpolated)
        requestAnimationFrame(run)
      } else {
        defaultScrollTo(interpolated)

        setTimeout(() => {
          // Add a small delay between the end of the spinner and the start of the scale animation.
          setScreenState(ScreenState.SELECTED)
        }, 100);
      }
    }

    requestAnimationFrame(run)
  }, [windowHeight])

  function onStartClicked() {
    onRollClicked()
  }

  function onRollClicked() {
    setScreenState(ScreenState.SCROLLING)

    const target = targetIndex + scrollDistanceMinimumNumberOfItems + Math.floor(Math.random() * numberOfSnacks())
    setTargetIndex(target)
    rowVirtualizer.scrollToIndex(target)
  }

  function onRerollClicked() {
    setSelectedImageClassName("layoutSelectedImageScaleDown")
    setBackgroundSelectedClassName("backgroundSelectedFadeOut")
  }

  function onSelectedImageAnimationEnd(event: AnimationEvent) {
    if (event.animationName === "animationScaleDown") {
      setSelectedImageClassName("layoutSelectedImageScaleUp")
      setBackgroundSelectedClassName("backgroundSelectedFadeIn")
      onRollClicked()
    }
  }

  console.log("c");
  

  const rowVirtualizer = useVirtual({
    size: numberOfItems,
    parentRef,
    estimateSize: React.useCallback(() => itemHeight, []),
    overscan: 5,
    scrollToFn,
  })

  console.log("start render");

  return (
    <div className="fullContent">
      <div className="grid">
        <div
          ref={parentRef}
          className="scrollContainer gridItem contentWidth">
          <div
            className="scrollContent"
            style={{ height: `${rowVirtualizer.totalSize}px` }}>
            {rowVirtualizer.virtualItems.map(virtualRow => (
              <div
                key={virtualRow.index}
                className="scrollItem"
                style={{ height: `${virtualRow.size}px`, transform: `translateY(${virtualRow.start}px)` }}>

                <img src={snackForIndex(virtualRow.index).image}
                  className="scrollItemImage" />
              </div>
            ))}
          </div>
        </div>

        {screenState == ScreenState.INTRO && <div className='gridItem'>
          <div className="intro">
            <div
              className='intro_background'>

              <img
                className='intro_logo'
                src={imageLogo} />

              <button
                className="button"
                onClick={() => onStartClicked()}
              >
                Kies mijn snack
              </button>
            </div>
          </div>
        </div>}

        {screenState == ScreenState.SELECTED && <div className='gridItem layoutSelectWrapper'>
          <div className="layoutSelected">
            <div className={`backgroundSelected ${backgroundSelectedClassName}`} />
            <div className="contentSelected contentWidth">
              <div className="layoutSelectedSpacer" />
              <div className='layoutSelectedCard'>
                <div className='layoutSelectedHeader'>
                  <h2 className='textItemSelected'>Wat dacht je van een heerlijke</h2>
                  <h1 className='textItemSelected'>{snackForIndex(targetIndex).name}</h1>
                </div>

                <img className={`layoutSelectedImage ${selectedImageClassName}`}
                  onAnimationEnd={onSelectedImageAnimationEnd}
                  src={snackForIndex(targetIndex).image}
                  height={itemHeight} />

                <div className='layoutSelectedFooter'>
                  <button
                    className="buttonSmall"
                    onClick={() => onRerollClicked()}
                  >
                    Toch liever iets anders
                  </button>
                </div>
              </div>

              <div className="layoutSelectedSpacer" />
            </div>
          </div>
        </div>}
      </div>
    </div >
  )
}