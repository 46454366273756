import imageBickyburger from '../images/snacks/bickyburger.png';
import imageChickenFingers from '../images/snacks/chickenfingers.png';
import imageBoulet from '../images/snacks/boulet.png';
import imageBouletSpecial from '../images/snacks/boulet-speciaal.png';
import imageFrikandel from '../images/snacks/frikandel.png';
import imageFrikandelSpecial from '../images/snacks/frikandel-speciaal.png';
import imageMexicano from '../images/snacks/mexicano.png';
import imageBami from '../images/snacks/bami.png';
import imageArdeenseSate from '../images/snacks/ardeense-sate.png';
import imageViandel from '../images/snacks/viandel.png';
import imageViandelSpecial from '../images/snacks/viandel-speciaal.png';
import imageBerepoot from '../images/snacks/berepoot.png';
import imageBitterballen from '../images/snacks/bitterballen.png';
import imageSitoStick from '../images/snacks/sitostick.png';
import imageCervela from '../images/snacks/cervela.png';
import imageCervelaSpecial from '../images/snacks/cervela-speciaal.png';
import imageGarnaalKroket from '../images/snacks/garnaalkroket.png';
import imageKaasKroket from '../images/snacks/kaaskroket.png';
import imageKipcorn from '../images/snacks/kipcorn.png';
import imageLoempia from '../images/snacks/loempia.png';
import imageStoofvlees from '../images/snacks/stoofvlees.png';
import imageVleesKroket from '../images/snacks/vleeskroket.png';
import imageZigeunerstick from '../images/snacks/zigeunerstick.png';
import imageSate from '../images/snacks/sate.png';
import imageLucifer from '../images/snacks/lucifer.png';
import imageRagouzi from '../images/snacks/ragouzi.png';

class Snack {
    name: string;
    image: string;

    constructor(name: string, image:string) {
        this.name = name;
        this.image = image;
    }
}

export var snacks = [
    new Snack("Ardeense saté", imageArdeenseSate),
    new Snack("Bami", imageBami),
    new Snack("Berepoot", imageBerepoot),
    new Snack("Bitterballen", imageBitterballen),
    new Snack("Bicky burger", imageBickyburger),
    new Snack("Boulet", imageBoulet),
    new Snack("Boulet special", imageBouletSpecial),
    new Snack("Cervela", imageCervela),
    new Snack("Cervela special", imageCervelaSpecial),
    new Snack("ChickenFingers", imageChickenFingers),
    new Snack("Frikandel", imageFrikandel),
    new Snack("Frikandel special", imageFrikandelSpecial),
    new Snack("Garnaalkroket", imageGarnaalKroket),
    new Snack("Kaaskroket", imageKaasKroket),
    new Snack("Kipcorn", imageKipcorn),
    new Snack("Loempia", imageLoempia),
    new Snack("Lucifer", imageLucifer),
    new Snack("Mexicano", imageMexicano),
    new Snack("Ragouzi", imageRagouzi),
    new Snack("Saté", imageSate),
    new Snack("Sitostick", imageSitoStick),
    new Snack("Stoofvlees", imageStoofvlees),
    new Snack("Viandel", imageViandel),
    new Snack("Viandel special", imageViandelSpecial),
    new Snack("Vleeskroket", imageVleesKroket),
    new Snack("Zigeunerstick", imageZigeunerstick),  
]

export function snackForIndex(index: number) {
    var numberOfSnacks = snacks.length
    var normalizedIndex = index % numberOfSnacks

    return snacks[normalizedIndex]
}

export function numberOfSnacks() {
    return snacks.length
}




